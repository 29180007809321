import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import ContactUs from "./pages/contact/ContactUs";
import About from "./pages/about/About";
import Location from "./pages/location/Location";
import Residential from "./pages/residential/Residential";
import Commercial from "./pages/commercial/Commercial";
import FlatsVillas from "./pages/FlatsVillas/FlatsVillas";
import Gallery from "./pages/gallery/Gallery";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/location" element={<Location />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/residential" element={<Residential />} />
            <Route path="/commercial" element={<Commercial />} />
            {/* <Route path="/flatsvillas" element={<FlatsVillas />} /> */}
            <Route path="/contact" element={<ContactUs />} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
