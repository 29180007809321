import React from "react";

const Gallery = () => {
  return (
    <>
      <section
        className="bg-no-repeat bg-center bg-cover bg-[#E9F1FF] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[70%]"
        style={{
          backgroundImage: 'url("assets/images/breadcrumb/bg-1.png")',
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="max-w-[700px]  mx-auto text-center text-white relative z-[1]">
                <h1 className="font-lora text-[32px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl font-medium">
                  Our Gallery
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <div className="properties-grids-section pt-[80px] pb-[120px] lg:py-[120px]">
        <div className="container">
          <div className="max-w-[460px] mx-auto">
            <h2 className=" font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-3 font-medium">
              our<span className="text-secondary"> Gallery</span>
            </h2>
          </div>
          <div className="columns-1 sm:columns-2 md:columns-3 gap-x-[30px] mb-[-30px]">
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/apartments.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Homes"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Flat/apartments
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/apartments1.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Flat/apartments
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/apartments3.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Appartments"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Flat/apartments
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Villas1.jpeg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Houses"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Villas
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Villas2.jpeg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="duplex house"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Villas
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Villas3.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Villas
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/StudioRooms.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Studio Rooms
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/StudioRooms1.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Homes"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Studio Rooms
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/StudioRooms3.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Studio Rooms
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/IndependentHouse.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Independent House
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/IndependentHouse1.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Appartments"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Independent House
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/IndependentHouse3.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Houses"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Independent House
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/HotelResorts.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="duplex house"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Hotel/Resorts
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/HotelResorts1.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Hotel/Resorts
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/HotelResorts3.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Hotel/Resorts
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/ServiceApartments.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Service Apartments
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/servicedapartments.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Service Apartments
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/serviced-apartments1.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Service Apartments
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/FarmHouses.jpeg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Farm Houses
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/FarmHouses1.jpeg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Farm Houses
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/FarmHouses2.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Farm Houses
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Warehouse.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Warehouse
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Warehouse1.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Warehouse
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Warehouse2.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Warehouse
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Warehouse3.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Warehouse
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/gallery/Warehouse4.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Warehouse
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
